import React from 'react';
import logo from '../assets/logo.svg';

const AgrementsUz = () => {
  return (
    <>
      <header style={{ textAlign: 'center', padding: 30 }}>
        <a href="/"><img src={logo} alt="road24" /></a>
      </header>
      <div className="privacy" style={{ padding: '20px 60px 40px' }}>
        <p><strong>“ROAD24” FOYDALANUVCHILARINING SHAXSIY MA’LUMOTLARINI QAYTA ISHLASHGA RUXSAT BERISH TO’G’RISIDAGI KELISHUV</strong></p>
          <p>Shaxsiy ma’lumotlarni qayta ishlash bo’yicha ushbu kelishuv (keyingi o’rinlarda Maxfiylik siyosati deb yuritiladi) “Road24” mobil ilovasi (keyingi o’rinlarda “Road24” yoki “Road24.uz” veb-sayti deb yuritiladi) foydalanuvchisi haqida ushbu mobil ilova va uning funksiyalaridan foydalanish vaqtida olinishi mumkin bo’lgan barcha ma’lumotlarga nisbatan qo’llaniladi. </p>
          <p></p>
          <p><strong>1. ATAMALAR TA’RIFI</strong></p>
          <p>1.1. Shaxsga doir ma’lumotlar — muayyan jismoniy shaxsga taalluqli bo’lgan yoki uni identifikatsiya qilish imkonini beradigan, elektron tarzda, qog’ozda va (yoki) boshqa moddiy jismda qayd etilgan axborot;</p>
          <p>Shaxsga doir ma’lumotlar subyekti (subyekt) — shaxsga doir ma’lumotlar o’ziga taalluqli bo’lgan jismoniy shaxs;</p>
          <p>Shaxsga doir ma’lumotlar bazasi — tarkibida shaxsga doir ma’lumotlar mavjud bo’lgan axborot tizimi tarzidagi ma’lumotlar bazasi;</p>
          <p>1.2. Shaxsga doir ma’lumotlarga ishlov berish — shaxsga doir ma’lumotlarni yig’ish, tizimlashtirish, saqlash, o’zgartirish, to’ldirish, ulardan foydalanish, ularni berish, tarqatish, uzatish, egasizlantirish va yo’q qilish bo’yicha bir harakatni yoki harakatlar majmuini amalga oshirish;</p>
          <p>1.3. «Shaxsiy ma’lumotlarning maxfiyligi» - Operator yoki shaxsiy ma’lumotlarga kirish huquqiga ega bo’lgan boshqa shaxs uchun shaxsiy ma’lumotlar subyektining roziligisiz yoki boshqa qonuniy asoslar mavjud bo’lmagan holda ularni tarqatishga yo’l qo’ymaslik to’g’risidagi majburiy talab.</p>
          <p>1.4. “Road24 foydalanuvchisi” – internet orqali veb-saytga (Road24.uz), mobil ilovaga kirish imkoniga ega bo’lgan va “Road24” ma’lumotlari, materiallari va mahsulotlaridan foydalanuvchi shaxs.</p>
          <p>1.5. “Jarima to’lov xizmatlari” – bu foydalanuvchi mobil ilovada yoki veb-saytda oladigan ma’lumot asosida “Road24” da qo’llaniladigan to’lov tizimi orqali to’laydigan mahsulotdir.</p>
          <p>1.6. “Shaxsiy ma’lumotlar operatori” yoki “Operator” – “Road24” mobil ilovasi mulkdori sifatida “KASH APP” mas’uliyati cheklangan jamiyati sifatida.</p>
          <p>1.7. Tomonlar Foydalanuvchi tomonidan hamma uchun ochiq bo’lgan shaxsiy ma’lumotlarni o’z ichiga olgan shaxsiy ma’lumotlar bazalari ro’yxatdan o’tkazilmasligini tasdiqlaydi.</p>
          <p>1.8. Shaxsiy ma’lumotlar tasdiqlangandan so’ng, foydalanuvchi o’z ma’lumotlarini qayta ishlashga roziligini beradi va uni hamma uchun ochiq qiladi.</p>
          <p><strong>2. UMUMIY QOIDALAR</strong></p>
          <p>2.1. Foydalanuvchining Road24.uz Saytidan yoki ilovasidan foydalanishni boshlashi ushbu kelishuv shartlarini va Foydalanuvchining shaxsiy ma’lumotlarini qayta ishlash shartlarini qabul qilganligini anglatadi.</p>
          <p>2.2. Mazkur kelishuv shartlariga rozi bo’lmagan taqdirda Foydalanuvchi «Road24» mobil ilovasi va Veb-saytdan () foydalanishni to’xtatishi shart. Foydalanuvchi tomonidan «Road24» mobil ilovasida ro’yxatdan o’tish shaxsiy ma’lumotlar subyektining ushbu kelishuv shartlariga roziligini bildiradi.</p>
          <p>2.3. Ushbu hujjat foydalanuvchi saytda ro’yxatdan o’tishda, axborot elektron pochtasiga obuna bo’lganda yoki buyurtma berishda operatorning iltimosiga binoan taqdim etadigan shaxsiy ma’lumotlarni qayta ishlash bo’yicha tomonlarning huquq va majburiyatlarini belgilaydi.</p>
          <p>2.4. Ushbu Kelishuv bo’yicha qayta ishlashga ruxsat berilgan shaxsiy ma’lumotlar foydalanuvchi tomonidan «Road24» mobil ilovasidagi shakllarni to’ldirish orqali taqdim etiladi va quyidagi ma’lumotlarni o’z ichiga oladi:</p>
          <p>2.4.1. F.I.O va foydalanuvchi pasportining seriyasi;</p>
          <p>2.4.2. Foydalanuvchining  telefon raqami;</p>
          <p>2.4.3. Foydalanuvchining elektron pochta manzili (elektron pochta);</p>
          <p>2.4.4. Foydalanuvchi jinsi;</p>
          <p>2.4.5. Foydalanuvchining tug’ilgan sanasi;</p>
          <p>2.4.6 Foydalanuvchining avtomobili haqidagi ma’lumotlar, xususan:</p>
          <p>1. Avtomobilning davlat raqami (hududiy kodi, seriyasi, davlat raqami va davlat identifikatori);</p>
          <p>2. Texnik ma’lumotlar passport seriyasi va uning raqami;</p>
          <p>3. Foydalanuvchi avtomobilining markasi va modeli.</p>
          <p>2.4.6.1. «Road24» mobil ilovasining «MyAuto» bo’limida ko’rsatilgan foydalanuvchining avtomobili haqidagi boshqa ma’lumotlar, xususan:</p>
          <p>1. Foydalanuvchining avtomashinasini sug’urta qilish to’g’risidagi ma’lumotlar;</p>
          <p>2. Foydalanuvchining avtomobilini texnik ko’rikdan o’tkazish va texnik ko’rikdan o’tkazilgan joy haqida ma’lumot. Tex.Tekshirish;</p>
          <p>3. Ushbu transport vositasidan foydalanish huquqiga ega bo’lgan shaxslarni va boshqa ma’lumotlarni ko’rsatgan holda foydalanuvchining avtomashinasiga ishonchnoma to’g’risidagi ma’lumotlar (ishonchnomaning amal qilish muddati, ishonchnoma turi);</p>
          <p>4. Qo’shimcha ma’lumotlar bilan foydalanuvchining avtomobilida oynalar tusini o’zgartirish mavjudligi to’g’risidagi ma’lumot (amal qilish muddati, oynalar tusini o’zgartirish turi va boshqa ma’lumotlar). Ushbu imkoniyatni taqdim etish uchun Operator boshqa tashkilotlar, jumladan my.gov.uz  bilan alohida aloqa o’rnatadi.</p>
          <p>5. Foydalanuvchining avtomobilining mashina moyini qo’shimcha ma’lumot bilan almashtirish to’g’risida ma’lumot;</p>
          <p>2.4.7. Foydalanuvchining avtomobili uchun jarimalar to’g’risidagi ma’lumotlar quyidagi ma’lumotlarni ko’rsatadi:</p>
          <p>1. Foydalanuvchining Avtomobili uchun olingan jarimalar soni;</p>
          <p>2. To’langan va to’lanmagan jarimalar tarixi;</p>
          <p>3. Jarima tafsilotlari quyidagi  ma’lumotlarni o’z ichiga oladi:</p>
          <p>a. Qo’yilgan jarimalar qarori raqamlari;</p>
          <p>b. Foydalanuvchi avtomobilining yo’l harakati qoidalarini buzgan paytdagi tasvirlari, avtomobilning davlat raqami tasviri;</p>
          <p>c. Foydalanuvchi avtomobilining markasi;</p>
          <p>d. O’zbekiston Respublikasi Ma’muriy javobgarlik to’g’risidagi kodeksining moddalariga asosan huquqbuzarlik tavsifi;</p>
          <p>e. Yo’l harakati qoidalari buzilgan joyining aniq manzilni ko’rsatish;</p>
          <p>f. Yo’l harakati qoidalarini buzilgan vaqti to’g’risidagi ma’lumotlar;</p>
          <p>g. O’zbekiston Respublikasining Ma’muriy javobgarlik to’g’risidagi kodeksining moddasi;</p>
          <p>h. Jarimalar qiymati davlat valyutasida ko’rsatish (so’mda);</p>
          <p>i. Raqamli hujjat PDF formatida va yuqorida tavsiflangan barcha ma’lumotlarni o’z ichiga oladi.</p>
          <p>j. Jarima majudligi haqida ma’lumotlar. </p>
          <p>2.4.8. «Road24» mobil ilovasining quyidagi funksiyalaridan foydalanish uchun foydalanuvchining geolokatsiya maʼlumotlari:</p>
          <p>1. Boshqa haydovchilar tomonidan yo’l harakati qoidalarini buzganligi to’g’risida Geo joylashuvi, ushbu qoidabuzarlik qayd etilgan videofayl, ushbu hodisa sodir bo’lgan sana va vaqtni ko’rsatgan holda bayonnoma/qaror yuborish;</p>
          <p>2.4.9. Quyidagi funksiyalar uchun moliyaviy operatsiyalarni amalga oshirish uchun foydalanuvchining bank kartasi rekvizitlari:</p>
          <p>1. Qisman yoki to’liq to’lash imkoniyati bilan Foydalanuvchining Avtomobili uchun qayd etilgan yo’l harakati qoidalarini buzganlik uchun jarima to’lash. haqidagi maʼlumot foydalanuvchiga “MyAuto > Jarimalar > Toʻlanmagan > Toʻlovlar tarixi” boʻlimida, shuningdek, “Road24” mobil ilovasining “Ogohlantirishlar” boʻlimida mavjud boʻladi.</p>
          <p>2. “Road24” mobil ilovasining “Xisobotlar” bo’limida yo’l harakati qoidabuzarligini qayd etganlik uchun mukofot olish.</p>
          <p>2.5. Foydalanuvchiga undan foydalanish imkoniyatini berish uchun Operator boshqa tashkilotlar, jumladan my.gov.uz bilan alohida aloqa o’rnatishga haqli.</p>
          <p>2.6. Ushbu ilova orqali opsionlar (ruxsatnomalar) xarid qilingan taqdirda, Foydalanuvchi Yagona interaktiv davlat xizmatlari portali xizmatlari narxi va Operator xarajatlaridan iborat bo’lgan xarid narxini qabul qiladi va unga rozi bo’ladi.</p>
          <p></p>
          <p><strong>3. SHAXSIY MA’LUMOTLARNI YIG’ISH MAQSADLARI</strong></p>
          <p>3.1. Operator foydalanuvchining shaxsiy ma’lumotlaridan quyidagi maqsadlarda foydalanish huquqiga ega:</p>
          <p>3.1.1. «Road24» mobil ilovasida ro’yxatdan o’tgan Foydalanuvchini identifikatsiyalash, boshqa haydovchilar tomonidan yo’l harakati qoidalarini buzganliklari to’g’risida bayonnoma tuzish va “Road24” mobil ilovasi doirasidagi boshqa harakatlarni amalga oshirish.</p>
          <p>3.1.2. Foydalanuvchiga «Road24» mobil ilovasi va veb-saytining () shaxsiy ma’lumotlariga kirishni ta’minlash.</p>
          <p>3.1.3. Foydalanuvchi bilan fikr-mulohazalarni o’rnatish, shu jumladan «Road24» mobil ilovasi va Veb-saytdan ().foydalanish bo’yicha bildirishnomalar, so’rovlar yuborish, xizmatlar ko’rsatish va Foydalanuvchining hisobotlarini qayta ishlash.</p>
          <p>3.1.4. Agar Foydalanuvchi akkaunt yaratishga rozi bo’lsa, «Road24» mobil ilovasi va Veb-sayt ().qismlaridan foydalanish uchun shaxsiy akkaunt yaratish.</p>
          <p>3.1.5. Foydalanuvchiga maxsus takliflar, Operator mahsulotlari va xizmatlari, Operator hamkorlari haqida ma’lumot, axborot byulletenlarini taqdim etish.</p>
          <p>3.1.6. Foydalanuvchiga sug’urta va oynalar tusini o’zgartirish bo’yicha qo’shimcha xizmatlarni taqdim etish va h.k.</p>
          <p>3.2. Foydalanuvchini «Road24» mobil ilovasida va veb-saytda () roʻyxatdan oʻtkazgandan soʻng, Operator ushbu kelishuvda nazarda tutilgan harakatlarni amalga oshirish uchun Foydalanuvchining shaxsiy maʼlumotlaridan foydalanish huquqiga ega.</p>
          <p>3.3. Foydalanuvchi ushbu ilova orqali oynalar tusini o’zgartirish, sug’urta polisi va boshqa ruxsatnomalarni sotib olishi, jarimalarni to’lashi mumkinligini qabul qiladi. O’z navbatida, Operator foydalanuvchi va Yagona interaktiv davlat xizmatlari portali o’rtasidagi platformaga aylanadi.</p>
          <p></p>
          <p><strong>4. SHAXSIY MA’LUMOTLARNI QAYTA ISHLASH USULLARI VA SHARTLARI</strong></p>
          <p>4.1. Foydalanuvchining shaxsiy ma’lumotlariga ishlov berish muddatsiz, har qanday qonuniy yo’l bilan, shu jumladan avtomatlashtirish vositalaridan foydalangan holda shaxsiy ma’lumotlarning axborot tizimlarida yoki bunday vositalardan foydalanmasdan amalga oshiriladi.</p>
          <p>4.2. Foydalanuvchi Operator shaxsiy ma’lumotlarni faqat ushbu kelishuvda nazarda tutilgan qayta ishlash maqsadlari uchun uchinchi shaxslarga o’tkazish huquqiga ega ekanligiga rozi bo’ladi.</p>
          <p>4.3. Operator foydalanuvchining shaxsiy ma’lumotlarini ruxsatsiz yoki tasodifiy kirish, yo’q qilish, o’zgartirish, bloklash, nusxalash, tarqatish, shuningdek uchinchi shaxslarning boshqa noqonuniy harakatlaridan himoya qilish uchun zarur tashkiliy va texnik choralarni ko’radi.</p>
          <p></p>
          <p><strong>5. KELISHUVNI BEKOR QILISH</strong></p>
          <p>Foydalanuvchi Telegramda Telegram: https://t.me/»Road24»yordam Operatorga bildirishnoma yuborish orqali shaxsiy ma’lumotlarni qayta ishlashga roziligini qaytarib olishga haqli, Foydalanuvchining shaxsiy ma’lumotlari Operator bajarishi uchun zarur bo’lgan hollar bundan mustasno. </p>
          <p></p>
          <p><strong>6. TOMONLARNING JAVOBGARLIGI</strong></p>
          <p>6.1. O’z majburiyatlarini aybdor ravishda bajarmagan Operator shaxsiy ma’lumotlardan noqonuniy foydalanish munosabati bilan Foydalanuvchiga yetkazilgan haqiqiy zarar uchun O’zbekiston Respublikasi qonunchiligiga muvofiq javobgar bo’ladi. Bunday holda, Foydalanuvchiga yetkazilgan haqiqiy zarar hujjatlar asosida isbotlanishi shart.</p>
          <p>6.2. Operator Foydalanuvchi oldida: “Road24” mobil ilovasidan va Veb-saytdan () foydalanish yoki foydalana olmaslik oqibatida ko’rilgan zararlar; foydalanuvchining aloqalariga ruxsatsiz kirilganda; Saytdagi har qanday uchinchi tomonning xatti-harakatlari  uchun javobgar emas</p>
          <p>6.3. Operator Foydalanuvchi tomonidan «Road24» mobil ilovasi va Veb-saytda () joylashtirilgan har qanday ma’lumotlar uchun javobgar emas.</p>
          <p></p>
          <p><strong>7. QO’SHIMCHA SHARTLAR</strong></p>
          <p>7.1. Operator foydalanuvchining roziligisiz ushbu kelishuvga va Maxfiylik siyosatiga o’zgartirishlar kiritish huquqiga ega.</p>
          <p>7.2. Maxfiylik siyosati «Road24» mobil ilovasida va veb-saytida () joylashtirilgan paytdan boshlab kuchga kiradi.</p>
          <p></p>
          <p></p>
      </div>
    </>
  );
}

export default AgrementsUz;
